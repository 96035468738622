import React, { useEffect, useState } from "react";
import { Form, Input, Button, message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { userService } from "./services/";
import { Admin, User } from "./screens";
import { IMAGES } from "./assets";

Spin.setDefaultIndicator(<LoadingOutlined style={{ fontSize: "40px" }} />);

const Container = styled.div`
  background-image: url(${IMAGES.bgPrimary});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  article {
    display: flex;
    @media (max-width: 955px) {
      flex-direction: column;
    }
    h1 {
      color: white;
    }
    section {
      margin: 10px;
      min-width: 300px;
    }
  }
`;

const App = () => {
  const [loading, setLoading] = useState(false);
  const [initializing, setInitializing] = useState(true);
  const [logged, setLogged] = useState(false);

  useEffect(() => {
    userService
      .verifyLogin()
      .then((user) => {
        if (user) {
          setLogged(true);
          setInitializing(false);
        } else setInitializing(false);
      })
      .catch(() => setInitializing(false));
  }, []);

  const onFinish = ({ email, password }) => {
    setLoading(1);
    userService
      .login(email, password)
      .then(() => {
        setLoading(false);
        setLogged(true);
      })
      .catch(() => {
        setLoading(false);
        message.error("Ocorreu um erro ao tentar efetuar seu login!");
      });
  };

  if (logged) return <Admin setLogged={setLogged} />;
  else
    return (
      <Container className="fade-in">
        <img
          alt="logo-4show"
          src={IMAGES.logo}
          style={{ width: "calc(220px + 5vw)" }}
        />
        {initializing ? (
          <Spin />
        ) : (
          <article className="fade-in">
            <section>
              <h1>
                Entre e monitore tudo <br />
                em tempo real!
              </h1>
              <Form id="form-login" name="form-login" onFinish={onFinish}>
                <Form.Item
                  name={"email"}
                  rules={[
                    { type: "email", message: "Informe um email válido!" },
                  ]}
                >
                  <Input
                    required
                    placeholder="Email"
                    id="email-login"
                    disabled={loading}
                  />
                </Form.Item>
                <Form.Item name={"password"}>
                  <Input.Password
                    required
                    placeholder="Senha"
                    id="password-login"
                    disabled={loading}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ float: "right" }}
                    loading={loading === 1}
                    disabled={loading}
                  >
                    Entrar
                  </Button>
                </Form.Item>
              </Form>
            </section>
          </article>
        )}
        <p
          style={{
            color: "white",
            maxWidth: "300px",
            textAlign: "center",
            position: "absolute",
            bottom: "20px",
          }}
        >
          © Copyright 2021 | 4Show | Todos os direitos reservados.
        </p>
      </Container>
    );
};

export default App;
