import React, { useState, useEffect } from 'react'
import { Table, Switch, message, Button, Modal, Input, Radio } from 'antd'
import { withRouter } from 'react-router-dom'
import { usersActions } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import { adminService } from '../../services'
import moment from 'moment'
import { LoadingOutlined, EditOutlined } from '@ant-design/icons'
import { validateEmail } from '../../utils'
import * as s from './styled-users'

const Users = ({ history }) => {
  const dispatch = useDispatch()
  const users = useSelector(state => state.users)
  //control
  const [loading, setLoading] = useState(true) //se está buscando usuários
  const [modalUser, setModalUser] = useState(false) //se a modal de usuário está aberta
  const [creatingUser, setCreatingUser] = useState(false) //se está salvando o usuário
  const [modalPass, setModalPass] = useState(false) //se a modal de trocar senha está aberta
  const [changingPass, setChangingPass] = useState(false) //se está trocando a senha
  //user
  const [edit, setEdit] = useState(false) //id do usuário sendo editado
  const [name, setName] = useState('') //nome do usuário
  const [type, setType] = useState('pf') //tipo do usuário
  const [email, setEmail] = useState('') //email do usuário
  const [nameParamLive, setNameParamLive] = useState('') //nome do usuario na live
  const [password, setPassword] = useState('') //senha
  const [password2, setPassword2] = useState('') //confirmação de senha
  const [percentage4Show, setPercentage4Show] = useState('1.00') //porcentagem do 4Show
  const [dueDate, setDueDate] = useState('') //data de vencimento
  const [showPoweredBy, setShowPoweredBy] = useState(true) //data de vencimento
  const [leads, setLeads] = useState(false)
  const [invalidValues, setInvalidValues] = useState([])

  useEffect(() => {
    document.title = `Usuários | 4Show`
    if (users && users.length > 0) setLoading(false)
    adminService
      .getContractors()
      .then(data => {
        dispatch(usersActions.setUsers(data))
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
        message.error(e)
      })
    return () => (document.title = `4Show`)
  }, [])
  //abre a modal de edição ou de novo usuário
  const openUserModalEdit = user => {
    if (user) {
      setName(user.name)
      setEmail(user.email)
      setNameParamLive(user.nameParamLive)
      setDueDate(user.dueDate.replaceAll('/', '-'))
      setType(user.type)
      setLeads(user.leads)
      setShowPoweredBy(user.whiteLabel.showPoweredBy)
      setPercentage4Show(
        user?.integrations?.payment?.pagarme?.percentage4Show?.toFixed(2)
      )
      setEdit(user.docId)
    } else {
      setName('')
      setEmail('')
      setNameParamLive('')
      setDueDate('')
      setType('pf')
      setLeads(false)
      setPercentage4Show('1.00')
      setPassword('')
      setPassword2('')
      setShowPoweredBy(true)
      setEdit()
    }
    setModalUser(true)
  }

  //troca a senha
  const changePass = () => {
    if (password !== password2)
      message.error('A confirmação de senha não coincide com a senha!')
    else {
      setChangingPass(true)
      adminService
        .updateContractorPassword({
          contractorId: edit,
          password
        })
        .then(() => {
          message.success('Senha de usuário alterada com sucesso!')
          setChangingPass(false)
          setModalPass(false)
          setPassword('')
          setPassword2('')
        })
        .catch(() => {
          setChangingPass(false)
          message.error(
            'Ocorreu um erro ao tentar alterar a senha deste usuário!'
          )
        })
    }
  }
  //salva o usuário
  const finishUser = () => {
    let data = {
      name,
      type,
      email,
      nameParamLive: nameParamLive.replace(' ', '-').toLowerCase(),
      dueDate: dueDate.replaceAll('-', '/'),
      percentage4ShowPagarme: Number(percentage4Show),
      showPoweredBy,
      leads
    }
    if (!edit) data.password = password
    else data.contractorId = edit

    if (!validateEmail(email))
      message.error('O email informado é não é válido!')
    else if (password !== password2)
      message.error('A confirmação de senha não coincide com a senha!')
    else {
      setCreatingUser(true)
      if (edit)
        adminService
          .updateContractor(data)
          .then(() => {
            setCreatingUser(false)
            message.success('Usuário alterado com sucesso!')
            setModalUser(false)
            adminService
              .getContractors()
              .then(data => dispatch(usersActions.setUsers(data)))
            setEdit(false)
          })
          .catch(() => {
            message.error('Ocorreu um erro ao tentar alterar esse usuário!')
            setCreatingUser(false)
          })
      else
        adminService
          .createContractor(data)
          .then(() => {
            setCreatingUser(false)
            message.success('Usuário cadastrado com sucesso!')
            setModalUser(false)
            adminService
              .getContractors()
              .then(data => dispatch(usersActions.setUsers(data)))
          })
          .catch(() => {
            message.error('Ocorreu um erro ao tentar criar este usuário!')
            setCreatingUser(false)
          })
    }
  }

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Nome na URL da live',
      dataIndex: 'nameParamLive',
      key: 'nameParamLive'
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      render: v => v.toUpperCase()
    },
    {
      title: 'Criado em',
      dataIndex: 'created',
      key: 'created',
      render: v => moment(v).format(`DD/MM/YYYY`)
    },
    {
      title: 'Expira em',
      dataIndex: 'dueDate',
      key: 'dueDate',
      render: v => moment(v).format(`DD/MM/YYYY`)
    },
    {
      title: 'Status',
      dataIndex: 'disabled',
      key: 'disabled',
      render: (v, user) => <DisableUser user={user} />
    },
    {
      render: (v, user) => (
        <EditOutlined
          style={{ cursor: 'pointer' }}
          onClick={() => openUserModalEdit(user)}
        />
      )
    }
  ]

  const checkInvalidValues = type => {
    let isInvalid = false
    if (type === 'nome' && !name) isInvalid = true
    else if ((type === 'email' && !email) || !validateEmail(email))
      isInvalid = true
    else if (type === 'porcentagem' && !percentage4Show) isInvalid = true
    else if (
      type === 'senha' &&
      (!password || !password2 || password !== password2)
    )
      isInvalid = true
    else if (type === 'expiracao' && !dueDate) isInvalid = true
    else if (type === 'nameLive' && !nameParamLive) isInvalid = true

    if (isInvalid) setInvalidValues([...invalidValues, type])
  }

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        justifyContent: 'space-evenly'
      }}
      className="fade-in"
    >
      <Modal
        visible={modalPass}
        title={`Alterar senha de ${name}`}
        okText={changingPass ? 'Alterando...' : 'Alterar'}
        cancelText="Voltar"
        okButtonProps={{
          loading: changingPass
        }}
        onOk={!changingPass && changePass}
        onCancel={() => !changingPass && setModalPass(false)}
      >
        Você tem certeza que deseja alterar a senha do usuário {name}? Se sim,
        qual a nova senha?
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: '10px', flex: 1 }}>
            Senha*
            <Input.Password
              value={password}
              onChange={({ target: t }) => {
                if (invalidValues.includes('senha'))
                  setInvalidValues(invalidValues.filter(x => x !== 'senha'))
                setPassword(t.value)
              }}
              disabled={changingPass}
              onBlur={() => checkInvalidValues('senha')}
            />
          </div>
          <div style={{ marginLeft: '10px', flex: 1 }}>
            Confirmação da senha*
            <Input.Password
              value={password2}
              onChange={({ target: t }) => {
                if (invalidValues.includes('senha'))
                  setInvalidValues(invalidValues.filter(x => x !== 'senha'))
                setPassword2(t.value)
              }}
              disabled={changingPass}
              onBlur={() => checkInvalidValues('senha')}
            />
          </div>
        </div>
        {invalidValues.includes('senha') && (
          <s.InvalidLabel className="fade-in">
            A senha e a confirmação da senha devem ser iguais
          </s.InvalidLabel>
        )}
      </Modal>
      <Modal
        visible={modalUser}
        title={
          <s.RequiredFields>
            {edit ? 'Editar' : 'Novo'} usuário
            <legend>Campos com * são obrigatórios</legend>
          </s.RequiredFields>
        }
        okText={creatingUser ? 'Salvando' : edit ? 'Alterar' : 'Cadastrar'}
        cancelText="Voltar"
        okButtonProps={{
          loading: creatingUser,
          disabled:
            !name || !type || !email || !nameParamLive || !dueDate || edit
              ? false
              : !password || !password2
        }}
        onOk={!creatingUser && finishUser}
        onCancel={() => !creatingUser && setModalUser(false)}
        bodyStyle={{ padding: '10px 24px 24px 24px' }}
      >
        <Radio.Group
          value={type}
          onChange={({ target: t }) => setType(t.value)}
          disabled={creatingUser}
          style={{ marginTop: 10 }}
        >
          <Radio value="pf">Pessoa Física</Radio>
          <Radio value="pj">Pessoa Jurídica</Radio>
        </Radio.Group>
        <div style={{ display: 'flex', margin: '10px 0' }}>
          <div style={{ width: '300px' }}>
            Nome*
            <Input
              name="name"
              value={name}
              onChange={({ target: t }) => {
                setName(t.value)
                if (invalidValues.includes('nome'))
                  setInvalidValues(invalidValues.filter(x => x !== 'nome'))
              }}
              disabled={creatingUser}
              onBlur={() => checkInvalidValues('nome')}
              style={{
                border: invalidValues.includes('nome') && '1px solid red'
              }}
            />
            {invalidValues.includes('nome') && (
              <s.InvalidLabel className="fade-in">
                Você precisa fornecer o nome do usuário
              </s.InvalidLabel>
            )}
          </div>
          <div style={{ width: '150px', marginLeft: 'auto' }}>
            Expiração*
            <Input
              type="date"
              name="expiration"
              value={dueDate}
              onChange={({ target: t }) => {
                if (invalidValues.includes('expiracao'))
                  setInvalidValues(invalidValues.filter(x => x !== 'expiracao'))
                setDueDate(t.value)
              }}
              disabled={creatingUser}
              onBlur={() => checkInvalidValues('expiracao')}
              style={{
                border: invalidValues.includes('expiracao') && '1px solid red'
              }}
            />
            {invalidValues.includes('expiracao') && (
              <s.InvalidLabel className="fade-in">
                Você precisa fornecer a data de expiração do usuário
              </s.InvalidLabel>
            )}
          </div>
        </div>
        <div style={{ display: 'flex', margin: '10px 0' }}>
          <div style={{ width: '300px' }}>
            Email*
            <Input
              type="email"
              name="email"
              value={email}
              onChange={({ target: t }) => {
                if (invalidValues.includes('email'))
                  setInvalidValues(invalidValues.filter(x => x !== 'email'))
                setEmail(t.value)
              }}
              onBlur={() => checkInvalidValues('email')}
              style={{
                border: invalidValues.includes('email') && '1px solid red'
              }}
            />
            {invalidValues.includes('email') && (
              <s.InvalidLabel className="fade-in">
                Você precisa fornecer o e-mail do usuário
              </s.InvalidLabel>
            )}
          </div>
          <div style={{ width: '150px', marginLeft: 'auto' }}>
            Nome na URL da live*
            <Input
              name="nameParamLive"
              value={nameParamLive}
              onChange={({ target: t }) => {
                if (invalidValues.includes('nameLive'))
                  setInvalidValues(invalidValues.filter(x => x !== 'nameLive'))
                setNameParamLive(t.value)
              }}
              onBlur={() => checkInvalidValues('nameLive')}
              style={{
                border: invalidValues.includes('nameLive') && '1px solid red'
              }}
            />
            {invalidValues.includes('nameLive') && (
              <s.InvalidLabel className="fade-in">
                Você precisa fornecer o nome na URL da live
              </s.InvalidLabel>
            )}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '10px 0'
          }}
        >
          <div>
            Porcentagem do 4Show pagar.me*
            <Input
              value={percentage4Show}
              type="number"
              maxLength={5}
              onWheel={e => e.currentTarget.blur()}
              addonAfter="%"
              onChange={({ target: t }) => {
                if (invalidValues.includes('porcentagem'))
                  setInvalidValues(
                    invalidValues.filter(x => x !== 'porcentagem')
                  )
                setPercentage4Show(t.value)
              }}
              disabled={creatingUser}
              onBlur={() => checkInvalidValues('porcentagem')}
              style={{
                border: invalidValues.includes('porcentagem') && '1px solid red'
              }}
            />
            {invalidValues.includes('porcentagem') && (
              <s.InvalidLabel className="fade-in">
                Você precisa fornecer a porcentagem do 4Show no pagar.me
              </s.InvalidLabel>
            )}
          </div>
          <div style={{ display: 'block' }}>
            <div style={{ marginLeft: '20px' }}>
              Exibir powered by
              <Switch
                checked={showPoweredBy}
                onChange={() => setShowPoweredBy(!showPoweredBy)}
                disabled={creatingUser}
              />
            </div>
            <div style={{ marginLeft: '20px' }}>
              Cadastrar Leads
              <Switch
                checked={leads}
                onChange={() => setLeads(!leads)}
                disabled={creatingUser}
              />
            </div>
          </div>
        </div>
        {edit ? (
          <Button
            type="primary"
            danger
            style={{ display: 'block', marginTop: '10px' }}
            onClick={() => setModalPass(true)}
            disabled={creatingUser}
          >
            Alterar senha
          </Button>
        ) : (
          <>
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: '10px', flex: 1 }}>
                Senha*
                <Input.Password
                  value={password}
                  onChange={({ target: t }) => {
                    if (invalidValues.includes('senha'))
                      setInvalidValues(invalidValues.filter(x => x !== 'senha'))
                    setPassword(t.value)
                  }}
                  disabled={creatingUser}
                  onBlur={() => checkInvalidValues('senha')}
                  style={{
                    border: invalidValues.includes('senha') && '1px solid red'
                  }}
                />
              </div>
              <div style={{ marginLeft: '10px', flex: 1 }}>
                Confirmação da senha*
                <Input.Password
                  value={password2}
                  onChange={({ target: t }) => {
                    if (invalidValues.includes('senha'))
                      setInvalidValues(invalidValues.filter(x => x !== 'senha'))
                    setPassword2(t.value)
                  }}
                  disabled={creatingUser}
                  onBlur={() => checkInvalidValues('senha')}
                  style={{
                    border: invalidValues.includes('senha') && '1px solid red'
                  }}
                />
              </div>
            </div>
            {invalidValues.includes('senha') && (
              <s.InvalidLabel className="fade-in">
                A senha e a confirmação da senha devem ser iguais
              </s.InvalidLabel>
            )}
          </>
        )}
      </Modal>
      {loading ? (
        <LoadingOutlined style={{ fontSize: '25px', marginTop: '40vh' }} />
      ) : users.length > 0 ? (
        <>
          <Table
            dataSource={users}
            columns={columns}
            footer={() => (
              <Button type="primary" onClick={() => openUserModalEdit()}>
                Novo usuário
              </Button>
            )}
            pagination={false}
            rowKey={row => row.docId}
          />
        </>
      ) : (
        <legend
          className="fade-in"
          style={{
            margin: ' 200px auto',
            maxWidth: '420px',
            textAlign: 'center',
            color: 'gray'
          }}
        >
          Não existem usuários cadastrados. Cadastre um usuário{' '}
          <a onClick={() => history.push('live-studio')}>aqui</a> e eles serão
          listados abaixo.
        </legend>
      )}
    </div>
  )
}

const DisableUser = ({ user }) => {
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(!user.disabled)

  const handleChange = () => {
    setLoading(true)
    ;(status
      ? adminService.disableContractor(user.docId)
      : adminService.enableContractor(user.docId)
    )
      .then(() => {
        setLoading(false)
        setStatus(!status)
      })
      .catch(() => {
        setLoading(false)
        message.error('Ocorreu um erro ao tentar alterar este usuário!')
      })
  }

  return <Switch loading={loading} checked={status} onChange={handleChange} />
}

export default withRouter(Users)
