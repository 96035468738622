import { userTypes } from "../actions";

const INITIAL_STATE = {};

export function user(state = INITIAL_STATE, action) {
  if (action.type === userTypes.SET_USER) {
    return { ...state, ...action.user };
  } else if (action.type === userTypes.RESET_STATE) {
    return INITIAL_STATE;
  } else return state;
}
