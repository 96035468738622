import { usersTypes } from "../actions";

const INITIAL_STATE = [];

export function users(state = INITIAL_STATE, action) {
  if (action.type === usersTypes.SET_USERS) {
    return [...action.users];
  } else if (action.type === usersTypes.RESET_STATE) {
    return INITIAL_STATE;
  } else return state;
}
