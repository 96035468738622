import { livesTypes } from "../actions";

const INITIAL_STATE = [];

export function lives(state = INITIAL_STATE, action) {
  if (action.type === livesTypes.SET_LIVES) {
    return [...action.lives];
  } else if (action.type === livesTypes.RESET_STATE) {
    return INITIAL_STATE;
  } else return state;
}
