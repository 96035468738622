import { createTypes } from "reduxsauce";

export const usersTypes = createTypes(
  `
  SET_USERS
  RESET_STATE
`,
  { prefix: "USERS/" }
);

const setUsers = (users) => {
  return (dispatch) => dispatch({ type: usersTypes.SET_USERS, users });
};
const resetState = () => {
  return (dispatch) => dispatch({ type: usersTypes.RESET_STATE });
};

export const usersActions = {
  setUsers,
  resetState,
};
