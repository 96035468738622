//IMAGES
import logo from "./images/logo.svg";
import bg from "./images/bg.svg";
import bgPrimary from "./images/bgPrimary.svg";
import fourWhite from "./images/4White.svg";

export const IMAGES = {
  logo,
  fourWhite,
  bg,
  bgPrimary,
};
export const COLORS = {
  primary: "rgb(106,49,137)",
  primaryOpacity: "rgba(106,49,137,0.6)",
  secondary: "rgb(255,192,92)",
  red: "rgb(250, 49, 49)",
  green: "rgb(107, 221, 97)",
};
