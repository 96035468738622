import { axiosApp } from '.'
//---------------------------------------------------
//--------------------Contractors--------------------
//---------------------------------------------------
const getContractors = () =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`admin/contractors`)
      .then(({ data }) => resolve(data))
      .catch(e =>
        reject(
          e && e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : 'Ocorreu um erro ao tentar buscar os usuários!'
        )
      )
  })
const disableContractor = id =>
  new Promise((resolve, reject) => {
    axiosApp
      .put(`admin/disableContractor/${id}`)
      .then(({ data }) => resolve(data))
      .catch(error => reject(error))
  })
const enableContractor = id =>
  new Promise((resolve, reject) => {
    axiosApp
      .put(`admin/enableContractor/${id}`)
      .then(({ data }) => resolve(data))
      .catch(error => reject(error))
  })
const createContractor = body =>
  new Promise((resolve, reject) => {
    axiosApp
      .post(`admin/createContractor`, body)
      .then(({ data }) => resolve(data))
      .catch(error => reject(error))
  })
const updateContractor = body =>
  new Promise((resolve, reject) => {
    axiosApp
      .put(`admin/updateContractor`, body)
      .then(({ data }) => resolve(data))
      .catch(error => reject(error))
  })
const updateContractorPassword = body =>
  new Promise((resolve, reject) => {
    axiosApp
      .put(`admin/updateContractorPassword`, body)
      .then(({ data }) => resolve(data))
      .catch(error => reject(error))
  })
//---------------------------------------------------
//-----------------------Lives-----------------------
//---------------------------------------------------
const getLives = () =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`admin/lives`)
      .then(({ data }) => resolve(data))
      .catch(e =>
        reject(
          e && e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : 'Ocorreu um erro ao tentar buscar as lives!'
        )
      )
  })
const duplicateLive = (liveId, owner, dataFim) =>
  new Promise((resolve, reject) => {
    axiosApp
      .post(`admin/duplicateLive`, {
        liveId,
        owner,
        dataFim
      })
      .then(({ data }) => resolve(data))
      .catch(e =>
        reject(
          e && e.response && e.response.data && e.response.data.message
            ? e.response.data.message
            : 'Ocorreu um erro ao tentar duplicar esta live!'
        )
      )
  })
//---------------------------------------------------
//----------------------Reports----------------------
//---------------------------------------------------
const userReport = (live, format) =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`admin/report/customers/${live}`, {
        params: { format },
        responseType: format === 'excel' ? 'arraybuffer' : 'text'
      })
      .then(({ data }) => resolve(data))
      .catch(e => reject(e))
  })

const cartReport = (live, format) =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`admin/report/favoritos/${live}`, {
        params: { format },
        responseType: format === 'excel' ? 'arraybuffer' : 'text'
      })
      .then(({ data }) => resolve(data))
      .catch(e => reject(e))
  })
const orderReport = (live, format) =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`admin/report/pedidos/${live}`, {
        params: { format },
        responseType: format === 'excel' ? 'arraybuffer' : 'text'
      })
      .then(({ data }) => resolve(data))
      .catch(e => reject(e))
  })

const chatReport = (live, format) =>
  new Promise((resolve, reject) => {
    axiosApp
      .get(`admin/report/chat/${live}`, {
        params: { format },
        responseType: format === 'excel' ? 'arraybuffer' : 'text'
      })
      .then(({ data }) => resolve(data))
      .catch(e => {
        let error = e.response.data
        if (format === 'excel') {
          let decoder = new TextDecoder('utf-8')
          error = JSON.parse(decoder.decode(error))
        }
        reject(error?.message ? error.message : false)
      })
  })

export const adminService = {
  //contractors
  getContractors,
  disableContractor,
  enableContractor,
  createContractor,
  updateContractor,
  updateContractorPassword,
  //lives
  getLives,
  duplicateLive,
  //reports
  userReport,
  cartReport,
  orderReport,
  chatReport
}
