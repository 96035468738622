import { createTypes } from "reduxsauce";

export const userTypes = createTypes(
  `
  SET_USER
  RESET_STATE
`,
  { prefix: "USER/" }
);

const setUser = (user) => {
  return (dispatch) => dispatch({ type: userTypes.SET_USER, user });
};
const resetState = () => {
  return (dispatch) => dispatch({ type: userTypes.RESET_STATE });
};

export const userActions = {
  setUser,
  resetState,
};
