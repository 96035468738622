import styled from "styled-components";
import { COLORS } from "../../assets";

export const ActiveFilter = styled.div`
  padding: 3px 10px;
  border-radius: 100px;
  background: ${({ green: g }) => (g ? COLORS.green : COLORS.red)};
  color: white;
  font-size: 13px;
  opacity: ${({ active: a }) => (a ? 1 : 0.4)};
  margin: 0 5px;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;
export const Active = styled(ActiveFilter)`
  position: absolute;
  top: 5px;
  right: 0px;
  cursor: default;
  opacity: 0.8;
  :hover {
    opacity: 0.8;
  }
`;
export const Title = styled.div`
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 8px;
`;
export const Meta = styled.div`
  color: rgba(0, 0, 0, 0.5);
`;
export const Filter = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: 15px;
`;
