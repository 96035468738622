import { auth, axiosApp } from "./";

const login = (email, senha) =>
  new Promise((resolve, reject) => {
    auth
      .signInWithEmailAndPassword(email, senha)
      .then((user) => resolve(user))
      .catch((error) => reject(error));
  });

const verifyLogin = () =>
  new Promise((resolve) => {
    auth.onAuthStateChanged((user) => {
      if (!user) resolve(false);
      else
        user
          .getIdToken()
          .then((t) => resolve(t))
          .catch(() => resolve(false));
    });
  });

const logout = () =>
  new Promise((resolve, reject) => {
    auth
      .signOut()
      .then(() => resolve())
      .catch((error) => reject(error));
  });

export const userService = {
  login,
  logout,
  verifyLogin,
};
