import { createTypes } from "reduxsauce";

export const livesTypes = createTypes(
  `
  SET_LIVES
  RESET_STATE
`,
  { prefix: "LIVES/" }
);

const setLives = (lives) => {
  return (dispatch) => dispatch({ type: livesTypes.SET_LIVES, lives });
};
const resetState = () => {
  return (dispatch) => dispatch({ type: livesTypes.RESET_STATE });
};

export const livesActions = {
  setLives,
  resetState,
};
