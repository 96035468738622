import React, { useState, useEffect } from "react";
import { Layout, Menu, Breadcrumb } from "antd";
import {
  UsergroupAddOutlined,
  DisconnectOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Route, Switch, Link, withRouter } from "react-router-dom";
import { Users, Lives } from "..";
import { userService } from "../../services";
import { useDispatch } from "react-redux";
import { userActions } from "../../store";
import { IMAGES } from "../../assets";

const { Content, Sider } = Layout;
const Admin = (props) => {
  const { location, history, setLogged } = props;
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);

  const breadcrumbNameMap = {
    "/": "Lives",
    "/users": "Usuários",
  };
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    return (
      <Breadcrumb.Item key={url}>
        {!["/products", "/security"].includes(url) ? (
          <Link to={url}>{breadcrumbNameMap[url]}</Link>
        ) : (
          breadcrumbNameMap[url]
        )}
      </Breadcrumb.Item>
    );
  });
  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  const logout = () => {
    userService.logout().then(() => {
      setLogged(false);
      dispatch(userActions.resetState());
    });
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed}>
        <div
          style={{
            height: "32px",
            margin: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            alt="logo"
            src={collapsed ? IMAGES.fourWhite : IMAGES.logo}
            style={{ height: "32px" }}
          />
        </div>
        <Menu
          theme="dark"
          defaultSelectedKeys={["/"]}
          mode="inline"
          selectedKeys={[location.pathname]}
        >
          <Menu.Item
            key="/"
            icon={<VideoCameraOutlined />}
            onClick={() => history.push("/")}
          >
            Lives
          </Menu.Item>
          <Menu.Item
            key="/users"
            icon={<UsergroupAddOutlined />}
            onClick={() => history.push("/users")}
          >
            Usuários
          </Menu.Item>
          <Menu.Item
            key="/logout"
            icon={<DisconnectOutlined />}
            onClick={logout}
          >
            Sair
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout
        style={{
          backgroundImage: `url(${IMAGES.bg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Content style={{ margin: "0 16px" }}>
          <Breadcrumb style={{ margin: "16px 0", display: "flex" }}>
            {breadcrumbItems}
          </Breadcrumb>
          <Switch>
            <Route path="/" exact component={Lives} />
            <Route path="/users" exact component={Users} />
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
};

export default withRouter(Admin);
