import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  FileTextOutlined,
  FileExcelOutlined,
  FileOutlined,
  UserSwitchOutlined,
  HeartOutlined,
  ShoppingCartOutlined,
  CopyOutlined
} from '@ant-design/icons'
import { Card, Skeleton, message, notification, Modal, Button } from 'antd'
import { adminService } from '../../services'
import { livesActions } from '../../store'
import * as s from './styled-lives'
import DuplicateModal from './duplicate-modal'

const { Meta } = Card
const Lives = () => {
  const dispatch = useDispatch()
  const lives = useSelector(state => state.lives)
  const [loading, setLoading] = useState(true)
  //filters
  const [filterOn, setFilterOn] = useState(false)
  const [filterOff, setFilterOff] = useState(false)

  useEffect(() => {
    document.title = `Lives | 4Show`
    if (lives && lives.length > 0) setLoading(false)
    adminService
      .getLives()
      .then(data => {
        dispatch(livesActions.setLives(data))
        setLoading(false)
      })
      .catch(e => {
        setLoading(false)
        message.error(e)
      })
    return () => (document.title = `4Show`)
  }, [])

  const changeFilterOnOff = on => {
    if (on) {
      setFilterOn(!filterOn)
      if (!filterOn) setFilterOff(false)
    } else {
      setFilterOff(!filterOff)
      if (!filterOff) setFilterOn(false)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        justifyContent: 'space-evenly'
      }}
      className="fade-in"
    >
      {loading ? (
        [1, 2, 3, 4].map(n => <MyCard loading key={n} />)
      ) : lives.length > 0 ? (
        <>
          <s.Filter>
            Filtros:
            <s.ActiveFilter
              className={`animated-linear ${filterOn && `shadow`}`}
              green
              title={'Live ativa'}
              active={filterOn}
              onClick={() => changeFilterOnOff(true)}
            >
              {'Publicada'}
            </s.ActiveFilter>
            <s.ActiveFilter
              className={`animated-linear ${filterOff && `shadow`}`}
              title={'Live desativada'}
              active={filterOff}
              onClick={() => changeFilterOnOff(false)}
            >
              {'Desativada'}
            </s.ActiveFilter>
          </s.Filter>
          {lives
            .filter(l => (filterOn ? l.active : filterOff ? !l.active : true))
            .map(l => (
              <MyCard live={l} key={l.docId} />
            ))}
        </>
      ) : (
        <legend
          className="fade-in"
          style={{
            margin: ' 200px auto',
            maxWidth: '420px',
            textAlign: 'center',
            color: 'gray'
          }}
        >
          Não existem lives cadastradas :/
        </legend>
      )}
    </div>
  )
}

const MyCard = ({ live, loading }) => {
  const [hasThumb, setHasThumb] = useState(
    loading ? false : live.thumbnail ? true : false
  )
  const [reportsModal, setReportsModal] = useState(false)
  const [duplicateLive, setDuplicateLive] = useState(false)

  let imgStyle = { width: '300px', height: '180px', background: '#e9e9e9' }

  function getTimestamp(live) {
    const dateString = live?.dataFim ?? ''
    const [date, time] = dateString.split(' ')
    const [day, month, year] = date.split('/')
    const dateFormat = `${month}/${day}/${year}`
    const stringData = dateFormat + ' ' + time
    const timestamp = new Date(stringData).getTime()
    return timestamp
  }

  return (
    <Card
      style={{ width: 300, margin: '10px', position: 'relative' }}
      className="shadow fade-in"
      cover={
        loading || !hasThumb ? (
          <Skeleton.Image style={imgStyle} active />
        ) : (
          <img
            style={imgStyle}
            alt="thumbnail"
            src={live.thumbnail}
            onError={() => setHasThumb(false)}
          />
        )
      }
      actions={
        !loading && [
          <FileTextOutlined
            key="reports"
            onClick={() => setReportsModal(true)}
          />,
          <CopyOutlined key="reports" onClick={() => setDuplicateLive(true)} />
        ]
      }
    >
      {loading ? (
        <Meta
          title={<Skeleton.Button active size={'default'} shape={'default'} />}
          description={
            <Skeleton.Input style={{ width: 200 }} active size={'default'} />
          }
        />
      ) : (
        <>
          <ReportsModal
            live={live}
            visible={reportsModal}
            onClose={() => setReportsModal(false)}
          />
          <DuplicateModal
            liveId={live.docId}
            dataFim={getTimestamp(live)}
            open={duplicateLive}
            onClose={() => setDuplicateLive(false)}
          />
          <s.Active
            green={live.active}
            title={live.active ? 'Live ativa' : 'Live desativada'}
          >
            {live.active ? 'Publicada' : 'Desativada'}
          </s.Active>
          <s.Title>{live.nome}</s.Title>
          <s.Meta>{live.descricao}</s.Meta>
          <s.Meta>
            <label>• Modo de transmissão:</label>
            <label>{live.aoVivo ? ` Ao vivo` : ` Gravada`}</label>
          </s.Meta>
          <s.Meta>
            <label>• Produtos:</label>
            <label>{live.realtime ? ` Com tempo` : ` Real time`}</label>
          </s.Meta>
          <s.Meta>
            <label>• Data de início: </label>
            <label>{live.dataInicio}</label>
          </s.Meta>
          <s.Meta>
            <label>• Data de término: </label>
            <label>{live.dataFim}</label>
          </s.Meta>
        </>
      )}
    </Card>
  )
}

const ReportsModal = ({ live, visible, onClose }) => {
  const [loadingUser, setLoadingUser] = useState(false) //buscando relatório dos usuários
  const [loadingCart, setLoadingCart] = useState(false) //buscando relatório dos carrinhos
  const [loadingOrder, setLoadingOrder] = useState(false) //buscando relatório dos pedidos
  const [loadingChat, setLoadingChat] = useState(false) //buscando relatório do chat

  const downloadData = (data, filename, format) => {
    var downloadLink = document.createElement('a')
    if (format === 'excel') {
      var blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
      var url = URL.createObjectURL(blob)
      downloadLink.href = url
      downloadLink.download = `${filename}.xlsx`
    } else {
      var blob = new Blob(['\ufeff', data])
      var url = URL.createObjectURL(blob)
      downloadLink.href = url
      downloadLink.download = `${filename}.${format}`
    }
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }
  const feedback = (success, key, description) => {
    if (success)
      notification.success({
        key,
        message: 'Download concluído!',
        onClick: () => notification.close(key),
        duration: 10
      })
    else
      notification.error({
        key,
        message: 'Ops!',
        description:
          description ||
          'Não foi possível baixar seu relatório agora! Por favor, tente novamente ou recarregue a página.',
        onClick: () => notification.close(key),
        duration: 10
      })
  }

  const downloadUserReport = format => {
    setLoadingUser(format)
    adminService
      .userReport(live.docId, format)
      .then(data => {
        downloadData(data, `Acessos - ${live.nome}`, format)
        setLoadingUser(false)
        feedback(true, 'user-report')
      })
      .catch(e => {
        setLoadingUser(false)
        feedback(false, 'user-report', e.response.data.message)
      })
  }

  const downloadCartReport = format => {
    setLoadingCart(format)
    adminService
      .cartReport(live.docId, format)
      .then(data => {
        downloadData(data, `Produtos curtidos - ${live.nome}`, format)
        setLoadingCart(false)
        feedback(true, 'cart-report')
      })
      .catch(e => {
        setLoadingCart(false)
        feedback(false, 'cart-report', e.response.data.message)
      })
  }

  const downloadOrderReport = format => {
    setLoadingOrder(format)
    adminService
      .orderReport(live.docId, format)
      .then(data => {
        downloadData(data, `Pedidos - ${live.nome}`, format)
        setLoadingOrder(false)
        feedback(true, 'order-report')
      })
      .catch(e => {
        setLoadingOrder(false)
        feedback(false, 'order-report', e.response.data.message)
      })
  }

  const downloadChatReport = format => {
    setLoadingChat(format)
    adminService
      .chatReport(live.docId, format)
      .then(data => {
        downloadData(data, `Chat - ${live.nome}`, format)
        setLoadingChat(false)
        feedback(true, 'report')
      })
      .catch(e => {
        setLoadingChat(false)
        feedback(false, 'report')
      })
  }
  return (
    <Modal
      title={`Relatórios - ${live.nome}`}
      visible={visible}
      footer={() => <div />}
      onCancel={() => onClose()}
    >
      <h3>
        <UserSwitchOutlined style={{ marginRight: '10px' }} />
        Usuários
      </h3>
      <div>
        Aqui você pode baixar o relatório de acessos dessa live. Todos os
        usuários que entraram na live estarão listados aqui.
      </div>
      <Button
        type="primary"
        onClick={() => downloadUserReport('csv')}
        style={{ margin: '20px 20px 0 0' }}
        loading={loadingUser === 'csv'}
        disabled={loadingUser === 'csv'}
      >
        <FileTextOutlined />
        Baixar relatório (CSV)
      </Button>
      <Button
        type="primary"
        onClick={() => downloadUserReport('excel')}
        style={{ margin: '20px 20px 0 0' }}
        loading={loadingUser === 'excel'}
        disabled={loadingUser === 'excel'}
      >
        <FileExcelOutlined />
        Baixar relatório (EXCEL)
      </Button>
      <h3 style={{ marginTop: '20px' }}>
        <HeartOutlined style={{ marginRight: '10px' }} />
        Produtos curtidos
      </h3>
      <div>
        Aqui está o relatório dos produtos que foram curtidos, mas que não foram
        convertidos em um pedido. Todos os produtos ainda curtidos da live
        estarão listados aqui.
      </div>
      <Button
        type="primary"
        onClick={() => downloadCartReport('csv')}
        style={{ margin: '20px 20px 0 0' }}
        loading={loadingCart === 'csv'}
        disabled={loadingCart === 'csv'}
      >
        <FileTextOutlined />
        Baixar relatório (CSV)
      </Button>
      <Button
        type="primary"
        onClick={() => downloadCartReport('excel')}
        style={{ margin: '20px 20px 0 0' }}
        loading={loadingCart === 'excel'}
        disabled={loadingCart === 'excel'}
      >
        <FileExcelOutlined />
        Baixar relatório (EXCEL)
      </Button>
      <h3 style={{ marginTop: '20px' }}>
        <ShoppingCartOutlined style={{ marginRight: '10px' }} />
        Pedidos finalizados
      </h3>
      <div>
        Aqui está o relatório dos pedidos que foram finalizados. Todos os
        pedidos que foram finalizados na live estarão listados aqui.
      </div>
      <Button
        type="primary"
        onClick={() => downloadOrderReport('csv')}
        style={{ margin: '20px 20px 0 0' }}
        loading={loadingOrder === 'csv'}
        disabled={loadingOrder === 'csv'}
      >
        <FileTextOutlined />
        Baixar relatório (CSV)
      </Button>
      <Button
        type="primary"
        onClick={() => downloadOrderReport('excel')}
        style={{ margin: '20px 20px 0 0' }}
        loading={loadingOrder === 'excel'}
        disabled={loadingOrder === 'excel'}
      >
        <FileExcelOutlined />
        Baixar relatório (EXCEL)
      </Button>

      <h3 style={{ marginTop: '20px' }}>
        <FileOutlined style={{ marginRight: '10px' }} />
        Comentaŕios do Chat
      </h3>
      <div>Aqui você pode baixar o relatório dos comentários dessa live.</div>
      <Button
        type="primary"
        onClick={() => downloadChatReport('csv')}
        style={{ margin: '20px 20px 0 0' }}
        loading={loadingChat === 'csv'}
        disabled={loadingChat === 'csv' || loadingChat === 'excel'}
      >
        <FileTextOutlined />
        Baixar relatório (CSV)
      </Button>
      <Button
        type="primary"
        onClick={() => downloadChatReport('excel')}
        style={{ margin: '20px 20px 0 0' }}
        loading={loadingChat === 'excel'}
        disabled={loadingChat === 'excel' || loadingChat === 'csv'}
      >
        {' '}
        <FileExcelOutlined />
        Baixar relatório (EXCEL)
      </Button>
    </Modal>
  )
}

export default Lives
