import styled from "styled-components";

export const InvalidLabel = styled.span`
  color: red;
  margin-bottom: 5px;
`;
export const RequiredFields = styled.div`
  display: grid;
  legend {
    font-weight: normal;
    color: gray;
    font-size: 12px;
    margin: 0 0 -5px 0;
  }
`;
