import React, { useEffect, useState } from 'react'
import { Modal, Table, message, notification } from 'antd'
import { usersActions } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import { adminService } from '../../services'
import { LoadingOutlined } from '@ant-design/icons'

const DuplicateModal = ({ liveId, open, onClose, dataFim }) => {
  const dispatch = useDispatch()
  const users = useSelector(state => state.users)
  const [loading, setLoading] = useState(true)
  const [duplicating, setDuplicating] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])

  useEffect(() => {
    if (open) {
      document.title = `Usuários | 4Show`
      if (users && users.length > 0) setLoading(false)
      else
        adminService
          .getContractors()
          .then(data => {
            dispatch(usersActions.setUsers(data))
            setLoading(false)
          })
          .catch(e => {
            setLoading(false)
            message.error(e)
          })
    }
    return () => (document.title = `4Show`)
  }, [open])

  const onFinish = () => {
    setDuplicating(true)
    adminService
      .duplicateLive(liveId, selectedRows[0].docId, dataFim) //keys que podem ser enviadas; liveId, owner, active,dataFim, friendlyUrl //
      .then(() => {
        notification.success({
          message: 'Live duplicada com sucesso!'
        })
        setDuplicating(false)
        onClose()
      })
      .catch(error => {
        setDuplicating(false)
        notification.error({ message: 'Ocorreu um erro!', description: error })
      })
  }

  const rowSelection = {
    onChange: (selectedRowKeys, rowsSelected) => setSelectedRows(rowsSelected),
    selectedRows
  }
  return (
    <Modal
      visible={open}
      title={'Duplicar live'}
      bodyStyle={{ padding: 0 }}
      onCancel={!duplicating && onClose}
      onOk={onFinish}
      cancelText="Voltar"
      okText="Duplicar"
      confirmLoading={duplicating}
    >
      <div style={{ margin: '20px' }}>
        Para qual usuário você deseja copiar essa live?
      </div>
      {loading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            margin: '60px 0'
          }}
        >
          <LoadingOutlined style={{ fontSize: '25px' }} />
        </div>
      ) : (
        <Table
          rowSelection={{
            type: 'radio',
            ...rowSelection
          }}
          rowKey="docId"
          dataSource={users}
          columns={columns}
          pagination={false}
          size="small"
          scroll={{ y: 320 }}
        />
      )}
    </Modal>
  )
}

export default DuplicateModal

const columns = [
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email'
  }
]
